// Generated by CoffeeScript 1.10.0
(function() {
  (function(p, $, d, w) {
    var MyDishes;
    MyDishes = (function() {
      var self;

      self = false;

      function MyDishes() {
        self = this;
        this.isMobileDevice = /Android|Kindle|Silk|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
      }

      MyDishes.prototype.isRunnable = function() {
        return $('#my-dishes-list').length > 0 && $(w).width() >= 768;
      };

      MyDishes.prototype.ready = function() {
        self = this;
        return $(w).load(function() {
          if (self.isRunnable()) {
            self.bindings();
            return self.load();
          }
        });
      };

      MyDishes.prototype.load = function() {};

      MyDishes.prototype.showLoading = function() {
        return $('#my-dishes-list .loading').show();
      };

      MyDishes.prototype.hideLoading = function() {
        return $('#my-dishes-list .loading').hide();
      };

      MyDishes.prototype.deleteDish = function(dishId) {
        var sendData;
        if (confirm('Sei sicuro di voler eliminare questo piatto?')) {
          self.showLoading();
          sendData = {
            'action': 'delete_dish',
            'id': dishId,
            'is_ajax': 1
          };
          return $.ajax({
            type: "POST",
            url: ajax_base_url,
            dataType: "json",
            data: sendData,
            success: function(data) {
              self.hideLoading();
              $('#my-dishes-list ul li.row-dish[data-dish-id="' + dishId + '"]').remove();
            }
          });
        } else {
          return self.hideLoading();
        }
      };

      MyDishes.prototype.bindings = function() {};

      return MyDishes;

    })();
    w.myDishes = new MyDishes();
    myDishes.ready();
  })($, jQuery, document, window);

}).call(this);
